
<template>
  <el-dialog
    :title="surveys.data.title"
    v-model="previewDialogVisible"
    width="60%"
    center
    :show-close="false"
  >
    <div v-for="(question, index) in surveys.data.questions" :key="question.id">
      <div class="question-title">
        <span>{{ index + 1 }}</span>
        {{ question.title }}
        <span class="required" v-if="question.required">*</span>
      </div>
      <div v-if="question.type === 'radio'">
        <el-radio-group>
          <el-radio
            v-for="option in question.question_options"
            :key="option.id"
            :label="option.title"
            class="item"
            >{{ option.title }}</el-radio
          >
        </el-radio-group>
      </div>
      <div v-else-if="question.type === 'checkbox'">
        <!-- <el-checkbox-group>
          <el-checkbox
            v-for="option in question.question_options"
            :key="option.title"
            :label="option.title"
          >{{option.title}}</el-checkbox>
        </el-checkbox-group> -->
        <el-checkbox
          v-for="option in question.question_options"
          :key="option.id"
          :label="option.title"
          class="item"
          >{{ option.title }}</el-checkbox
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, ref } from "vue";
export default {
  data() {
    const previewDialogVisible = ref(false);
    const surveys = reactive({
      data: {},
    });

    const openPreviewDialog = (val) => {
      previewDialogVisible.value = true;
      surveys.data = val;
    };
    const closePreviewDialog = () => {
      previewDialogVisible.value = false;
    };
    return {
      previewDialogVisible,
      closePreviewDialog,
      openPreviewDialog,
      surveys,
    };
  },
};
</script>

<style>
.question-title {
  padding-top: 10px;
  font-weight: bold;
  color: black;
}
.item {
  width: 100%;
  padding: 20px 0px 0px 10px;
  color: black;
}
.required {
  color: red;
}
</style>
