<template>
  <div>
    <el-dialog
      :title="getDialogTitle"
      v-model="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="answerForm">
        <el-form-item :label="t('answer.wx_user_id')" prop="wx_user_id">
          <el-input-number v-model="ruleForm.wx_user_id" controls-position="right" style="width:100%"></el-input-number>
        </el-form-item>
        <el-form-item :label="t('answer.survey_id')" prop="survey_id">
          <el-input-number v-model="ruleForm.survey_id" controls-position="right" style="width:100%"></el-input-number>
        </el-form-item>
        <el-form-item :label="t('answer.question_id')" prop="question_id">
          <el-input-number v-model="ruleForm.question_id" controls-position="right" style="width:100%"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{ t("common.save") }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, watch, getCurrentInstance, computed } from "vue";
export default {
  name: "answer_dialog",
  props: {
    row: Object,
    type: String,
  },
  setup(props) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const dialogVisible = ref(false);

    const ruleForm = reactive({
      wx_user_id: 0,
      survey_id: 0,
      question_id: 0,
    });

    const answerForm = ref(null);

    const rules = reactive({
      wx_user_id: [{ required: true, message: `${t('common.input_validate')}`, trigger: "blur" }],
      survey_id: [{ required: true, message: `${t('common.input_validate')}`, trigger: "blur" }],
      question_id: [{ required: true, message: `${t('common.input_validate')}`, trigger: "blur" }],
    });

    const resetForm = () => {
      answerForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const submitForm = () => {
      answerForm.value.validate((valid) => {
        if (valid) {
          if (props.type === "create") {
            proxy.$emit("submit", ruleForm);
          } else {
            proxy.$emit("edit", ruleForm);
          }
        }
      });
    };

    const handleClose = () => {
      answerForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const openDialog = () => {
      answerForm.value?.resetFields();
      dialogVisible.value = true;
    };

    const closeDialog = () => {
      dialogVisible.value = false;
    };

    watch(props, () => {
      if (props.type !== "create") {
        ruleForm.id = props.row.id;
        ruleForm.wx_user_id = props.row.wx_user_id ? props.row.wx_user_id : 0;
        ruleForm.survey_id = props.row.survey_id ? props.row.survey_id : 0;
        ruleForm.question_id = props.row.question_id ? props.row.question_id : 0;
      } else {
        ruleForm.wx_user_id = 0;
        ruleForm.survey_id = 0;
        ruleForm.question_id = 0;
      }
    });

    const getDialogTitle = computed(() => {
      switch (props.type) {
          case "create":
            return t("common.create");
          case "detail":
            return t("common.detail");
          default:
            return t("common.edit");
      }
    })

    return {
      t,
      dialogVisible,
      answerForm,
      rules,
      ruleForm,
      openDialog,
      closeDialog,
      handleClose,
      resetForm,
      submitForm,
      getDialogTitle
    };
  },
};
</script>

<style>
</style>
