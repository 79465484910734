<template>
  <div class="content">
    <el-row :gutter="10" type="flex" align="middle" class="search">
      <el-col :span="8">
        <el-input
          size="medium"
          v-model="params.keyword"
          :placeholder="t('common.search_placeholder')"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button size="small" type="primary" @click="handleSearch">{{
          t("common.search")
        }}</el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          size="small"
          type="primary"
          @click="showDialog(null, 'create')"
          >{{ t("common.create") }}</el-button
        >
      </el-col>
      <el-col :span="2">
        <upload-excel-component
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        />
      </el-col>
    </el-row>

    <el-table :data="tableInfo.data">
      <el-table-column
        type="index"
        width="80"
        :label="t('common.number')"
      ></el-table-column>
      <el-table-column
        prop="title"
        :label="t('survey.title')"
      ></el-table-column>
      <el-table-column
        prop="description"
        :label="t('survey.description')"
      ></el-table-column>
      <el-table-column prop="status" :label="t('survey.status')">
        <template #default="scope">
          <span>{{ scope.row.status ? "启用" : "禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="creator" :label="t('survey.creator')">
        <template #default="scope">
          <span>{{ scope.row.user.name }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="position"
        :label="t('survey.position')"
      ></el-table-column> -->
      <el-table-column fixed="right" :label="t('common.action')" width="160">
        <template #default="scope">
          <el-button @click="enableSurvey(scope.row)" type="text" size="small">
            {{ scope.row.status ? t("survey.disabled") : t("survey.enabled") }}
          </el-button>
          <el-button
            @click="showPreviewDialog(scope.row)"
            type="text"
            size="small"
            >{{ t("survey.preview") }}</el-button
          >
          <el-button
            v-if="false"
            @click="showDialog(scope.row, 'detail')"
            type="text"
            size="small"
            >{{ t("common.detail") }}</el-button
          >
          <el-button
            @click="showDialog(scope.row, 'update')"
            type="text"
            size="small"
            >{{ t("common.edit") }}</el-button
          >
          <el-button
            @click="handleDelete(scope.row)"
            type="text"
            class="text"
            size="small"
            >{{ t("common.delete") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableInfo.pagination.page"
      :page-sizes="[20, 40, 60]"
      :page-size="tableInfo.pagination.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableInfo.pagination.total_entities"
    >
    </el-pagination>

    <SurveyDialog
      ref="surveyDialog"
      :type="dialogType"
      :row="currentRow"
      @submit="handleCreate"
      @edit="handleEdit"
    ></SurveyDialog>

    <PreviewDialog ref="previewDialog"></PreviewDialog>

    <SyncDialog ref="syncDialog" @submit="handleSyncSurveys"></SyncDialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted, ref } from "vue";
import { ElMessage } from "element-plus";
import { search, get, create, update, remove, syncSurveys, surveyEnable } from "@/api/survey";
import SurveyDialog from "./survey_dialog.vue";
import PreviewDialog from "./preview.vue";
import SyncDialog from "./sync_dialog.vue";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
export default {
  name: "survey",
  components: {
    SurveyDialog,
    UploadExcelComponent,
    PreviewDialog,
    SyncDialog,
  },
  setup() {
    const { t } = useI18n();

    const params = reactive({
      page: 1,
      per_page: 20,
      keyword: "",
    });

    const dialogType = ref(null);
    const currentRow = ref({});
    const surveyDialog = ref(null);
    const previewDialog = ref(null);
    const syncDialog = ref(null);

    const syncSurveysParams = reactive({
      survey_title: "",
      questions: [
        {
          type: "",
          question_title: "",
          question_options: [],
        },
      ],
    });

    let tableInfo = reactive({
      data: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_entities: 0,
        total_pages: 0,
      },
    });

    const showDialog = (row, type) => {
      dialogType.value = type;
      currentRow.value = row;

      surveyDialog.value.openDialog();
    };

    const handleCreate = async (row) => {
      const res = await create(row);
      if (res.error_code === 0) {
        surveyDialog.value.closeDialog();
        ElMessage.success(t("common.create_prompt"));
        getSurveyData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDetail = async (row) => {
      const res = await get({ id: row.id });
      console.log("res", res);
    };

    const handleEdit = async (row) => {
      const res = await update(row);
      if (res.error_code === 0) {
        surveyDialog.value.closeDialog();
        ElMessage.success(t("common.update_prompt"));
        getSurveyData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDelete = async (row) => {
      const res = await remove({ id: row.id });
      if (res.error_code === 0) {
        ElMessage.success(t("common.delete_prompt"));
        getSurveyData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleSearch = (params) => {
      console.log("params", params);
      getSurveyData();
    };

    const handleSizeChange = (val) => {
      params.per_page = val;
      getSurveyData();
    };

    const handleCurrentChange = (val) => {
      params.page = val;
      getSurveyData();
    };

    const getSurveyData = async () => {
      const res = await search(params);
      if (res.error_code === 0) {
        tableInfo.data = res.response.data;
        tableInfo.pagination = res.response.pagination;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const beforeUpload = (file) => {
      const isLt100M = file.size / 1024 / 1024 < 100;

      if (isLt100M) {
        return true;
      }

      ElMessage.error({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    };

    const handleSuccess = ({ results, header }) => {
      // 需要处理excel中没有相关字段时，产生的错误
      console.log("header", header);
      console.log("results", results);
      if (
        header.includes("问题类型") &&
        header.includes("问题描述") &&
        header.includes("问题选项")
      ) {
        syncSurveysParams.questions = results.map((old) => {
          return {
            type:
              old["问题类型"] === "单选"
                ? "radio"
                : old["问题类型"] === "多选"
                ? "checkbox"
                : "text",
            question_title: old["问题描述"],
            question_options: old["问题选项"].split("/"),
          };
        });
        syncDialog.value.openDialog();
      } else {
        ElMessage.error(t("survey.prompt"));
      }
    };

    const showPreviewDialog = async (row) => {
      const res = await get({ id: row.id });
      console.log("res", res);
      if (res.error_code === 0) {
        previewDialog.value.openPreviewDialog(res.response);
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleSyncSurveys = async (val) => {
      console.log("val", val);
      syncSurveysParams.survey_title = val;
      const res = await syncSurveys(syncSurveysParams);
      if (res.error_code === 0) {
        syncDialog.value.handleClose();
        getSurveyData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const enableSurvey = async (row) => {
      const res = await surveyEnable({
        survey_id: row.id,
        type: row.status ? "stop" : "start"
      })
      if (res.error_code === 0) {
        getSurveyData();
      } else {
        ElMessage.error(res.error_message);
      }
    }

    onMounted(() => {
      // 获取数据
      getSurveyData();
    });
    return {
      t,
      params,
      tableInfo,
      surveyDialog,
      currentRow,
      showDialog,
      dialogType,
      handleCreate,
      handleDetail,
      handleEdit,
      handleDelete,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      beforeUpload,
      handleSuccess,
      previewDialog,
      showPreviewDialog,
      syncDialog,
      handleSyncSurveys,
      syncSurveysParams,
      enableSurvey
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0px 20px 0px 20px;
}

.search {
  margin-top: 20px;
}

.text {
  color: #f56c6c;
}
.pagination {
  float: right;
  margin: 20px 0px 20px 0px;
}
</style>
