import service from '@/utils/request'

// 获取Answer列表
export const search = (params) => {
    return service({
        url: '/answers',
        method: 'get',
        params
    })
}

// 获取指定Answer的详情
export const get = (params) => {
    return service({
        url: `/answer/${params.id}`,
        method: 'get'
    })
}

// 创建Answer
export const create = (data) => {
    return service({
        url: '/answer',
        method: 'post',
        data
    })
}

// 更新Answer信息
export const update = (data) => {
    return service({
        url: `/answer/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Answer
export const remove = (data) => {
    return service({
        url: `/answer/${data.id}`,
        method: 'delete',
        data
    })
}
