import service from '@/utils/request'

// 获取Survey列表
export const search = (params) => {
    return service({
        url: '/surveys',
        method: 'get',
        params
    })
}

// 获取指定Survey的详情
export const get = (params) => {
    return service({
        url: `/survey/${params.id}`,
        method: 'get'
    })
}

// 创建Survey
export const create = (data) => {
    return service({
        url: '/survey',
        method: 'post',
        data
    })
}

// 更新Survey信息
export const update = (data) => {
    return service({
        url: `/survey/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Survey
export const remove = (data) => {
    return service({
        url: `/survey/${data.id}`,
        method: 'delete',
        data
    })
}

// sync Survey
export const syncSurveys = (data) => {
    return service({
        url: '/sync/surveys',
        method: 'post',
        data
    })
}

// enabled Survey
export const surveyEnable = (data) => {
    return service({
        url: '/survey/enable',
        method: 'post',
        data
    })
}
