<template>
  <div>
    <el-dialog
      :title="getDialogTitle"
      v-model="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="surveyForm">
        <el-form-item :label="t('survey.title')" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item :label="t('survey.description')" prop="description">
          <el-input v-model="ruleForm.description" type="textarea"></el-input>
        </el-form-item>
        <el-row type="flex" :gutter="20" v-if="false">
          <el-col :span="12">
            <el-form-item :label="t('survey.position')" prop="position">
              <el-input-number
                v-model="ruleForm.position"
                controls-position="right"
                style="width: 50%"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="t('survey.status')" prop="status">
              <el-switch
                v-model="ruleForm.status"
                style="width: 50%"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            t("common.save")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, watch, getCurrentInstance, computed } from "vue";
export default {
  name: "survey_dialog",
  props: {
    row: Object,
    type: String,
  },
  setup(props) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const dialogVisible = ref(false);

    const ruleForm = reactive({
      title: "",
      description: "",
      status: false,
      position: 0,
    });

    const surveyForm = ref(null);

    const rules = reactive({
      title: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      description: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      position: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const resetForm = () => {
      surveyForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const submitForm = () => {
      surveyForm.value.validate((valid) => {
        if (valid) {
          if (props.type === "create") {
            proxy.$emit("submit", ruleForm);
          } else {
            proxy.$emit("edit", ruleForm);
          }
        }
      });
    };

    const handleClose = () => {
      surveyForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const openDialog = () => {
      surveyForm.value?.resetFields();
      dialogVisible.value = true;
    };

    const closeDialog = () => {
      dialogVisible.value = false;
    };

    watch(props, () => {
      if (props.type !== "create") {
        ruleForm.id = props.row.id;
        ruleForm.title = props.row.title ? props.row.title : "";
        ruleForm.description = props.row.description
          ? props.row.description
          : "";
        ruleForm.status = props.row.status ? props.row.status : false;
        ruleForm.position = props.row.position ? props.row.position : 0;
      } else {
        ruleForm.title = "";
        ruleForm.description = "";
        ruleForm.status = false;
        ruleForm.position = 0;
      }
    });

    const getDialogTitle = computed(() => {
      switch (props.type) {
        case "create":
          return t("common.create");
        case "detail":
          return t("common.detail");
        default:
          return t("common.edit");
      }
    });

    return {
      t,
      dialogVisible,
      surveyForm,
      rules,
      ruleForm,
      openDialog,
      closeDialog,
      handleClose,
      resetForm,
      submitForm,
      getDialogTitle,
    };
  },
};
</script>

<style>
</style>
