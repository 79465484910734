<template>
  <el-dialog :title="t('survey.import')" v-model="syncDialogVisible" width="30%">
    <el-form :model="syncForm" :rules="syncRules" ref="syncSurveyForm">
      <el-form-item :label="t('survey.title')" prop="survey_title">
        <el-input v-model="syncForm.survey_title"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
        <el-button type="primary" @click="submitSyncForm">{{
          t("common.save")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, ref } from "vue";
export default {
  name: "sync_dialog",
  emits: ["submit"],
  setup(_, { emit }) {
    const { t } = useI18n();
    const syncDialogVisible = ref(false);

    const syncForm = reactive({
      survey_title: "",
    });

    const syncRules = reactive({
      survey_title: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const syncSurveyForm = ref(null);

    const handleClose = () => {
      syncSurveyForm.value?.resetFields();
      syncDialogVisible.value = false;
    };

    const openDialog = () => {
      syncSurveyForm.value?.resetFields();
      syncDialogVisible.value = true;
    };

    const closeDialog = () => {
      syncDialogVisible.value = false;
    };

    const resetForm = () => {
      syncSurveyForm.value?.resetFields();
      syncDialogVisible.value = false;
    };

    const submitSyncForm = () => {
      syncSurveyForm.value.validate((valid) => {
        if (valid) {
          emit("submit", syncForm.survey_title);
        }
      });
    };
    return {
      t,
      syncDialogVisible,
      handleClose,
      openDialog,
      closeDialog,
      syncSurveyForm,
      syncForm,
      syncRules,
      resetForm,
      submitSyncForm,
    };
  },
};
</script>