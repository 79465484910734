<template>
  <div class="content">
    <el-row :gutter="10" type="flex" align="middle" class="search">
      <el-col :span="8">
        <span class="search-text">{{ t("question.questionnaire") + ":" }}</span>
        <el-select
          v-model="params.survey_id"
          clearable
          style="width: 70%"
          :placeholder="t('common.select')"
        >
          <el-option
            v-for="item in surveys.data"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <span class="search-text">{{ t("question.question_type") + ":" }}</span>
        <el-select
          v-model="params.type"
          clearable
          style="width: 70%"
          :placeholder="t('common.select')"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="10" type="flex" align="middle" class="search">
      <el-col :span="8">
        <span class="search-text">{{ t("common.search_text") + ":" }}</span>
        <el-input
          size="medium"
          v-model="params.keyword"
          :placeholder="t('common.search_placeholder')"
          style="width: 70%"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button size="small" type="primary" @click="handleSearch">{{
          t("common.search")
        }}</el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          size="small"
          type="primary"
          @click="showDialog(null, 'create')"
          >{{ t("common.create") }}</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="tableInfo.data">
      <el-table-column
        type="index"
        width="80"
        :label="t('common.number')"
      ></el-table-column>
      <el-table-column
        prop="title"
        :label="t('question.title')"
      ></el-table-column>
      <el-table-column prop="type" :label="t('question.type')">
        <template #default="scope">
          <span>{{ getQuestionType(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="description"
        :label="t('question.description')"
      ></el-table-column> -->
      <el-table-column prop="organ_type" :label="t('question.organ_type')">
        <template #default="scope">
          <span>{{
            scope.row.organ_type === "kidney"
              ? "肾脏"
              : scope.row.organ_type === "spleen"
              ? "脾脏"
              : scope.row.organ_type === "liver"
              ? "肝脏"
              : scope.row.organ_type === "lung"
              ? "肺脏"
              : "通用"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="required" :label="t('question.required')">
        <template #default="scope">
          <span>{{ scope.row.required ? "必答" : "非必答" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="position"
        :label="t('question.position')"
      ></el-table-column> -->
      <el-table-column fixed="right" :label="t('common.action')" width="120">
        <template #default="scope">
          <el-button
            @click="showDialog(scope.row, 'detail')"
            type="text"
            size="small"
            >{{ t("common.detail") }}</el-button
          >
          <el-button
            @click="showDialog(scope.row, 'update')"
            type="text"
            size="small"
            >{{ t("common.edit") }}</el-button
          >
          <el-button
            @click="handleDelete(scope.row)"
            type="text"
            class="text"
            size="small"
            >{{ t("common.delete") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableInfo.pagination.page"
      :page-sizes="[20, 40, 60]"
      :page-size="tableInfo.pagination.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableInfo.pagination.total_entities"
    >
    </el-pagination>

    <QuestionDialog
      ref="questionDialog"
      :type="dialogType"
      :row="currentRow"
      @submit="handleCreate"
      @edit="handleEdit"
      :options="typeOptions"
      :surveys="surveys.data"
    ></QuestionDialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted, ref } from "vue";
import { search, get, create, update, remove } from "@/api/question";
import { search as getSurveyList } from "@/api/survey";
import QuestionDialog from "./question_dialog.vue";
import { ElMessage } from "element-plus";
export default {
  name: "question",
  components: {
    QuestionDialog,
  },
  setup() {
    const { t } = useI18n();

    const params = reactive({
      page: 1,
      per_page: 20,
      keyword: "",
      survey_id: "",
      type: "",
    });

    const typeOptions = reactive([
      {
        label: "单选",
        value: "radio",
      },
      {
        label: "多选",
        value: "checkbox",
      },
      // {
      //   label: "填空",
      //   value: "text",
      // },
    ]);

    const dialogType = ref(null);
    const currentRow = ref({});
    const questionDialog = ref(null);

    let tableInfo = reactive({
      data: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_entities: 0,
        total_pages: 0,
      },
    });

    const surveys = reactive({
      data: [],
    });

    const showDialog = (row, type) => {
      dialogType.value = type;
      currentRow.value = row;

      questionDialog.value.openDialog();
    };

    const handleCreate = async (row) => {
      console.log('into create method')
      const res = await create(row);
      if (res.error_code === 0) {
        console.log('into create method success')
        questionDialog.value.closeDialog();
        ElMessage.success(t("common.create_prompt"));
        getQuestionData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDetail = async (row) => {
      const res = await get({ id: row.id });
      console.log("res", res);
    };

    const handleEdit = async (row) => {
      const res = await update(row);
      if (res.error_code === 0) {
        questionDialog.value.closeDialog();
        ElMessage.success(t("common.update_prompt"));
        getQuestionData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDelete = async (row) => {
      const res = await remove({ id: row.id });
      if (res.error_code === 0) {
        ElMessage.success(t("common.delete_prompt"));
        getQuestionData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleSearch = (params) => {
      console.log("params", params);
      getQuestionData();
    };

    const handleSizeChange = (val) => {
      params.per_page = val;
      getQuestionData();
    };

    const handleCurrentChange = (val) => {
      params.page = val;
      getQuestionData();
    };

    const getQuestionData = async () => {
      const res = await search(params);
      if (res.error_code === 0) {
        tableInfo.data = res.response.data;
        tableInfo.pagination = res.response.pagination;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const getQuestionType = (type) => {
      switch (type) {
        case "radio":
          return "单选";
        case "checkbox":
          return "多选";
        default:
          return "填空";
      }
    };

    const getSurveyData = async () => {
      const res = await getSurveyList({ page: 1, per_page: 1000 });
      if (res.error_code === 0) {
        surveys.data = res.response.data;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    onMounted(() => {
      // 获取数据
      getQuestionData();
      getSurveyData();
    });
    return {
      t,
      params,
      tableInfo,
      questionDialog,
      currentRow,
      showDialog,
      dialogType,
      handleCreate,
      handleDetail,
      handleEdit,
      handleDelete,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      typeOptions,
      getQuestionType,
      surveys,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0px 20px 0px 20px;
}

.search {
  margin-top: 20px;
}

.text {
  color: #f56c6c;
}
.pagination {
  float: right;
  margin: 20px 0px 20px 0px;
}
</style>
