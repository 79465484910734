<template>
  <div>
    <el-dialog
      :title="getDialogTitle"
      v-model="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="questionForm">
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item :label="t('question.questionnaire')" prop="survey_id">
              <el-select
                v-model="ruleForm.survey_id"
                clearable
                style="width: 100%"
                :placeholder="t('common.select')"
              >
                <el-option
                  v-for="item in surveys"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="t('question.organ_type')" prop="organ_type">
              <el-select
                v-model="ruleForm.organ_type"
                clearable
                style="width: 100%"
                :placeholder="t('common.select')"
              >
                <el-option
                  v-for="item in OrganOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="t('question.title')" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="t('question.description')" prop="description">
          <el-input v-model="ruleForm.description"></el-input>
        </el-form-item> -->
        <el-form-item :label="t('question.type')" prop="type">
          <!-- <el-select v-model="ruleForm.type" style="width: 100%">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <el-radio-group v-model="ruleForm.type" style="width: 100%">
            <el-radio
              v-for="item in options"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-row type="flex" :gutter="20">
          <!-- <el-col :span="10">
            <el-form-item :label="t('question.type')" prop="type">
              <el-select v-model="ruleForm.type" style="width: 100%">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12" v-if="false">
            <el-form-item :label="t('question.position')" prop="position">
              <el-input-number
                v-model="ruleForm.position"
                controls-position="right"
                style="width: 50%"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="t('question.required')" prop="required">
              <el-switch
                v-model="ruleForm.required"
                style="width: 50%"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="space-between"
          align="middle"
          v-if="ruleForm.type !== 'text'"
        >
          <el-col :span="6">
            <el-tag size="mini" effect="dark">{{
              t("question.answer_options") + " :"
            }}</el-tag>
          </el-col>
          <el-col :span="6">
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              style="float: right"
              size="mini"
              @click="addOption"
              >{{ t("question.add_option") }}</el-button
            >
          </el-col>
        </el-row>

        <el-row v-if="ruleForm.type !== 'text'">
          <el-form-item
            class="dynamic-form-item"
            v-for="(option, index) in ruleForm.options"
            :label="t('question.option') + index"
            :key="option.key"
            :prop="'options.' + index + '.title'"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur',
            }"
          >
            <el-input v-model="option.title" style="width: 50%"></el-input>
            <el-button
              type="danger"
              size="medium"
              style="margin-left: 15px"
              @click.prevent="removeOption(option)"
              >{{ t("common.delete") }}</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            t("common.save")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, watch, getCurrentInstance, computed } from "vue";
import { removeQuestionOption } from "@/api/question";
import { ElMessage } from "element-plus";
export default {
  name: "question_dialog",
  props: {
    row: Object,
    type: String,
    options: Array,
    surveys: Array,
  },
  setup(props) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const dialogVisible = ref(false);

    const ruleForm = reactive({
      survey_id: "",
      title: "",
      type: "",
      organ_type: "common",
      description: "",
      required: false,
      position: 0,
      options: [
        {
          id: 0,
          title: "",
          position: 0,
        },
      ],
    });

    const OrganOptions = reactive([
      {
        label: "通用",
        value: "common",
      },
      {
        label: "肾脏",
        value: "kidney",
      },
      {
        label: "脾脏",
        value: "spleen",
      },
      {
        label: "肝脏",
        value: "liver",
      },
      {
        label: "肺脏",
        value: "lung",
      },
    ]);

    const questionForm = ref(null);

    const rules = reactive({
      survey_id: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      description: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      required: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      position: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const resetForm = () => {
      questionForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const submitForm = () => {
      questionForm.value.validate((valid) => {
        if (valid) {
          console.log('click button:', props.type)
          if (props.type === "create") {
            console.log('click submit button:', props.type)
            proxy.$emit("submit", ruleForm);
          } else {
            proxy.$emit("edit", ruleForm);
          }
        }
      });
    };

    const handleClose = () => {
      questionForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const openDialog = () => {
      questionForm.value?.resetFields();
      dialogVisible.value = true;
      ruleForm.options = [
        {
          id: 0,
          title: "",
          position: 0,
        },
      ];
    };

    const closeDialog = () => {
      questionForm.value?.resetFields();
      dialogVisible.value = false;
      ruleForm.options = [
        {
          id: 0,
          title: "",
          position: 0,
        },
      ];
    };

    watch(props, () => {
      if (props.type !== "create") {
        ruleForm.id = props.row.id;
        ruleForm.title = props.row.title ? props.row.title : "";
        ruleForm.type = props.row.type ? props.row.type : "";
        ruleForm.organ_type = props.row.organ_type
          ? props.row.organ_type
          : "common";
        ruleForm.description = props.row.description
          ? props.row.description
          : "";
        ruleForm.required = props.row.required ? props.row.required : false;
        ruleForm.position = props.row.position ? props.row.position : 0;
        ruleForm.survey_id = props.row.survey ? props.row.survey.id : "";
        // 渲染已有的问题
        if (
          props.row.question_options &&
          props.row.question_options.length > 0
        ) {
          let newQuestionOptions = [];
          newQuestionOptions = props.row.question_options.map((old) => {
            return {
              id: old["id"],
              title: old["title"],
              key: Date.now() + old["id"],
              position: old["position"],
            };
          });
          ruleForm.options = newQuestionOptions;
        } else {
          ruleForm.options = [
            {
              id: 0,
              title: "",
              position: 0,
            },
          ];
        }
      } else {
        ruleForm.title = "";
        ruleForm.type = "";
        ruleForm.organ_type = "common";
        ruleForm.description = "";
        ruleForm.required = false;
        ruleForm.position = 0;
        ruleForm.survey_id = "";
        ruleForm.options = [
          {
            id: 0,
            title: "",
            position: 0,
          },
        ];
      }
    });

    const getDialogTitle = computed(() => {
      switch (props.type) {
        case "create":
          return t("common.create");
        case "detail":
          return t("common.detail");
        default:
          return t("common.edit");
      }
    });

    const addOption = () => {
      ruleForm.options.push({
        id: 0,
        title: "",
        key: Date.now(),
        position: 0,
      });
    };

    const removeOption = (obj) => {
      var index = ruleForm.options.indexOf(obj);
      if (index !== -1) {
        ruleForm.options.splice(index, 1);
      }
      if (obj.id != 0) {
        // 删除数据
        handleRemoveOption(obj);
      }
    };

    const handleRemoveOption = async (obj) => {
      const res = await removeQuestionOption({ id: obj.id });
      if (res.error_code === 0) {
        ElMessage.success(t("common.delete_prompt"));
      } else {
        ElMessage.error(res.error_message);
      }
    };

    return {
      t,
      dialogVisible,
      questionForm,
      rules,
      ruleForm,
      openDialog,
      closeDialog,
      handleClose,
      resetForm,
      submitForm,
      getDialogTitle,
      addOption,
      removeOption,
      OrganOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.dynamic-form-item {
  margin-top: 15px;
  width: 100%;
}
</style>
