import service from '@/utils/request'

// 获取Question列表
export const search = (params) => {
    return service({
        url: '/questions',
        method: 'get',
        params
    })
}

// 获取指定Question的详情
export const get = (params) => {
    return service({
        url: `/question/${params.id}`,
        method: 'get'
    })
}

// 创建Question
export const create = (data) => {
    return service({
        url: '/question',
        method: 'post',
        data
    })
}

// 更新Question信息
export const update = (data) => {
    return service({
        url: `/question/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Question
export const remove = (data) => {
    return service({
        url: `/question/${data.id}`,
        method: 'delete',
        data
    })
}

// 删除Question option
export const removeQuestionOption = (data) => {
    return service({
        url: `/option/${data.id}`,
        method: 'delete',
        data
    })
}
