<template>
  <div class="content">
    <el-row :gutter="10" type="flex" align="middle" class="search">
      <el-col :span="8">
        <el-input
          size="medium"
          v-model="params.keyword"
          :placeholder="t('common.search_placeholder')"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button size="small" type="primary" @click="handleSearch"
          >{{ t("common.search") }}</el-button
        >
      </el-col>
      <el-col :span="2" v-if="false">
        <el-button
          size="small"
          type="primary"
          @click="showDialog(null, 'create')"
          >{{ t("common.create") }}</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="tableInfo.data">
      <el-table-column type="index" width="80" :label="t('common.number')"></el-table-column>
      <el-table-column prop="wx_user_id" :label="t('answer.wx_user_id')">
        <template #default="scope">
          <span>{{ scope.row.wx_user.nickname }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="survey_id" :label="t('answer.survey_id')">
        <template #default="scope">
          <span>{{ scope.row.survey.title }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="question_id" :label="t('answer.question_id')">
        <template #default="scope">
          <span>{{ scope.row.question.title }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="question_id" :label="t('answer.question_type')">
        <template #default="scope">
          <span>{{ scope.row.question.type==='radio' ? "单选" : scope.row.question.type==='checkbox' ? "多选" : "填空" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="answer_options" :label="t('answer.answer')">
        <template #default="scope">
          <el-tag v-for="(item, index) in getAnswerOptions(scope.row.answer_options)" :key="index" size="small" type="info" style="margin: 2px 5px 0px 0px">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="t('common.action')" width="120" v-if="false">
        <template #default="scope">
          <el-button
            @click="showDialog(scope.row, 'detail')"
            type="text"
            size="small"
            >{{ t("common.detail") }}</el-button
          >
          <el-button
            @click="showDialog(scope.row, 'update')"
            type="text"
            size="small"
            >{{ t("common.edit") }}</el-button
          >
          <el-button
            @click="handleDelete(scope.row)"
            type="text"
            class="text"
            size="small"
            >{{ t("common.delete") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableInfo.pagination.page"
      :page-sizes="[20, 40, 60]"
      :page-size="tableInfo.pagination.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableInfo.pagination.total_entities"
    >
    </el-pagination>

    <AnswerDialog ref="answerDialog" :type="dialogType" :row="currentRow" @submit="handleCreate" @edit="handleEdit"></AnswerDialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted, ref } from "vue";
import { ElMessage } from "element-plus";
import {
  search,
  get,
  create,
  update,
  remove,
} from "@/api/answer";
import AnswerDialog from "./answer_dialog.vue";
export default {
  name: "answer",
  components: {
    AnswerDialog,
  },
  setup() {

    const { t } = useI18n();

    const params = reactive({
      page: 1,
      per_page: 20,
      keyword: "",
    });

    const dialogType = ref(null);
    const currentRow = ref({});
    const answerDialog = ref(null);

    let tableInfo = reactive({
      data: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_entities: 0,
        total_pages: 0,
      },
    });

    const showDialog = (row, type) => {
      dialogType.value = type;
      currentRow.value = row;

      answerDialog.value.openDialog();
    };

    const handleCreate = async (row) => {
      const res = await create(row);
      if (res.error_code === 0) {
        answerDialog.value.closeDialog()
        ElMessage.success(t("common.create_prompt"));
        getAnswerData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDetail = async (row) => {
      const res = await get({ id: row.id });
      console.log("res", res);
    };

    const handleEdit = async (row) => {
      const res = await update(row);
      if (res.error_code === 0) {
        answerDialog.value.closeDialog()
        ElMessage.success(t("common.update_prompt"));
        getAnswerData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDelete = async (row) => {
      const res = await remove({ id: row.id });
      if (res.error_code === 0) {
        ElMessage.success(t("common.delete_prompt"));
        getAnswerData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleSearch = (params) => {
      console.log("params", params);
      getAnswerData();
    };

    const handleSizeChange = (val) => {
      params.per_page = val;
      getAnswerData();
    };

    const handleCurrentChange = (val) => {
      params.page = val;
      getAnswerData();
    };

    const getAnswerData = async () => {
      const res = await search(params);
      if (res.error_code === 0) {
        tableInfo.data = res.response.data;
        tableInfo.pagination = res.response.pagination;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const getAnswerOptions = (arr) => {
      var answerArray = []
      arr.forEach(element => {
        if (element.question_option_id !== 0) {
          answerArray.push(element.question_option.title)
        } else {
          answerArray.push(element.content)
        }
      });
      return answerArray
    }

    onMounted(() => {
      // 获取数据
      getAnswerData();
    });
    return {
      t,
      params,
      tableInfo,
      answerDialog,
      currentRow,
      showDialog,
      dialogType,
      handleCreate,
      handleDetail,
      handleEdit,
      handleDelete,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      getAnswerOptions
    };
  },
};
</script>

<style lang="scss" scoped>

.content {
  padding: 0px 20px 0px 20px;
}

.search {
  margin-top: 20px;
}

.text {
  color: #f56c6c;
}
.pagination {
  float: right;
  margin: 20px 0px 20px 0px;
}
</style>
